import { Component, ChangeDetectionStrategy } from '@angular/core';
import { isMobile } from '@core/utils';
import { CookieAcceptService, CookieActions } from '../cookie-accept.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { LangToggleComponent } from '../../../core/components/lang-toggle/lang-toggle.component';

@Component({
  selector: 'pbb-manage-cookies-page',
  templateUrl: './manage-cookies-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionModule,
    MatCheckboxModule,
    MatDividerModule,
    NgClass,
    MatButtonModule,
    TranslateModule,
    LangToggleComponent,
  ],
})
export class ManageCookiesPageComponent {
  isMobile = isMobile();
  isCookieSelected: boolean = true;
  constructor(private readonly cookieService: CookieAcceptService) {}

  changeCookieSelected(event: MatCheckboxChange) {
    this.isCookieSelected = event.checked;
  }

  save() {
    if (this.isCookieSelected) {
      this.cookieService.close(CookieActions.ACCEPT);
    } else {
      this.cookieService.close(CookieActions.ACCEPT_ONLY_ESSENTIAL);
    }
  }
  openCookieNotice() {
    this.cookieService.close(CookieActions.NOTICE);
  }
  openTerms() {
    this.cookieService.close(CookieActions.TERMS);
  }
}
