import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormStateService extends UntilDestroy {
  personalDetailsForm!: FormGroup;
  hasChange!: boolean;
}

export function GetFormControl(formControlName: string, formGroup: FormGroup): FormControl {
  return <FormControl>formGroup.get(formControlName);
}
