import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CookieAcceptService, CookieActions } from '@cookie/cookie-accept.service';
import { Languages, LanguageService } from '@core/services/language.service';
import { isMobile } from '@core/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangToggleComponent } from '../../../core/components/lang-toggle/lang-toggle.component';

@Component({
  selector: 'pbb-cookie-prompt-drawer',
  templateUrl: './cookie-prompt-drawer.component.html',
  styleUrls: ['./cookie-prompt-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LangToggleComponent, NgClass, MatButtonModule, NgIf, TranslateModule],
})
export class CookiePromptDrawerComponent {
  Languages = Languages;
  viewDetail = false;
  isCheckout = false;
  isMobile = isMobile();
  constructor(
    public translateService: TranslateService,
    public languageService: LanguageService,
    private readonly cookieService: CookieAcceptService
  ) {}

  acceptCookies() {
    this.cookieService.close(CookieActions.ACCEPT);
  }

  manageCookies() {
    this.cookieService.close(CookieActions.MANAGE);
  }

  openTerms() {
    this.cookieService.close(CookieActions.TERMS);
  }
}
