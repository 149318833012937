<div class="content">
  <div mat-dialog-content class="content_main-otc text-center">
    <h1 class="mt-48" id="otc-verification-title">{{ 'OTC.VERIFICATION.TITLE' | translate }}</h1>
    <div class="flex flex-row flex-center mt-8">
      <span class="mat-body-2 text-dark-charcoal" id="otc-verification-subtitle">
        {{ 'OTC.VERIFICATION.SUBTITLE' | translate }}
      </span>
    </div>
    <div class="flex flex-row flex-center mt-32 mb-53">
      <img id="otc-verification-img" src="assets/images/envelope.png" width="120px" alt="" />
    </div>
    <form>
      <mat-form-field floatLabel="always" appearance="fill" class="show-error-untouched">
        <mat-label>{{ 'OTC.VERIFICATION.LABEL' | translate }}</mat-label>
        <input
          id="otc-verification-code-input"
          type="text"
          matInput
          [formControl]="otcCodeFormControl"
          placeholder="000000"
          maxlength="6"
        />
        <mat-error
          pbb-form-field-error
          role="alert"
          class="mb-32"
          label="otc_code"
          [pbbFormControl]="otcCodeFormControl"
        ></mat-error>
      </mat-form-field>
    </form>

    <button
      mat-flat-button
      color="primary"
      class="mt-20"
      (click)="submit()"
      id="otc-verification-submit-btn"
      [disabled]="otcCodeFormControl.invalid || !otcCodeFormControl.value"
    >
      {{ 'COMMON.BUTTON.CONTINUE' | translate }}
    </button>
    <div class="text-divider mt-20">
      <span>
        {{ 'COMMON.OR' | translate }}
      </span>
    </div>
    <a
      mat-flat-button
      color="accent"
      class="mt-29"
      href="https://konek.ca"
      target="_blank"
      id="otc-verification-learn-more"
      rel="noopener noreferrer"
    >
      {{ 'OTC.VERIFICATION.LEARN_MORE' | translate }}
    </a>
  </div>
</div>
