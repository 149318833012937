import { Inject, Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  cookiePromptBottomSheetConfig,
  fullScreenModalConfig,
  partialGrayedScreenModalConfig,
} from '@core/constants/modal.config';
import { SNAME } from '@core/constants/storage';
import { APP_CONFIG } from '@core/interfaces/configs';
import { LoggerService } from '@core/services/logger.service';
import { TmxService } from '@core/services/tmx.service';
import { WindowService } from '@core/services/window.service';
import { APPCONFIG, TMX } from '@core/tokens/configs';
import * as Utils from '@core/utils';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable, filter, take } from 'rxjs';
import { CookiePromptDrawerComponent } from './cookie-prompt-drawer/cookie-prompt-drawer.component';
import { ManageCookiesPageComponent } from './manage-cookies-page/manage-cookies-page.component';
import { OtcSubmitComponent } from './otc-submit/otc-submit.component';

export enum CookieActions {
  'ACCEPT',
  'ACCEPT_ONLY_ESSENTIAL',
  'MANAGE',
  'NOTICE',
  'TERMS',
}
@Injectable({
  providedIn: 'root',
})
export class CookieAcceptService {
  promptInProgress: boolean = false;
  bottomSheetRef!: MatBottomSheetRef<CookiePromptDrawerComponent | ManageCookiesPageComponent>;
  dialogRef!: MatDialogRef<CookiePromptDrawerComponent | ManageCookiesPageComponent>;
  nonEssential = true;
  essential = true;
  constructor(
    @Inject(TMX) private tmxService: TmxService,
    @Inject(APPCONFIG) private appConfig: APP_CONFIG,
    private readonly _bottomSheet: MatBottomSheet,
    private _dialog: MatDialog,
    private readonly router: Router,
    private window: Window,
    private windowService: WindowService,
    private logger: LoggerService,
    private gtmService: GoogleTagManagerService
  ) {}

  check(trustedDevice = false, acceptEssential = false) {
    this.essential = trustedDevice || !!sessionStorage.getItem(SNAME.ACCEPT_COOKIES);
    this.nonEssential = JSON.parse(sessionStorage.getItem(SNAME.ACCEPT_NONESSENTIAL) || 'false') || acceptEssential;
    if (
      this.window.location.pathname.includes('cookies-notice') ||
      this.window.location.pathname.includes('manage-cookies') ||
      this.window.location.pathname.includes('error') ||
      this.promptInProgress
    ) {
      return;
    }
    this.logger.log('COOKIE', 'CHECK');

    if (this.essential) {
      if (this.nonEssential) {
        this.logger.log('COOKIE', 'NONESSENTIAL ACCEPTED');
        this.gtmService.addGtmToDom();
        this.logger.log('GTM', 'ADDED TO DOME');
      } else {
        this.logger.log('COOKIE', 'NONESSENTIAL DECLINED');
      }

      this.windowService.tmxSessionId$
        .pipe(
          filter((tmxSessionId) => !!tmxSessionId),
          take(1)
        )
        .subscribe((tmxSessionId) => {
          this.tmxService.startProfiling(tmxSessionId);
        });
      return;
    }
    this.prompt();
  }

  prompt() {
    this.promptInProgress = true;
    if (this.appConfig.enableOTC && !sessionStorage.getItem(SNAME.OTC_VERIFIED)) {
      this.logger.log('OTC', 'PROMPT');
      this._dialog
        .open(OtcSubmitComponent, {
          ...fullScreenModalConfig,
          autoFocus: false,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((res) => {
          sessionStorage.setItem(SNAME.OTC_VERIFIED, 'true');
          this.cookiePrompt();
        });
    } else {
      this.cookiePrompt();
    }
  }

  cookiePrompt() {
    this.logger.log('COOKIE', 'PROMPT');
    let close$: Observable<CookieActions>;
    if (Utils.isMobile()) {
      this.bottomSheetRef = this._bottomSheet.open(CookiePromptDrawerComponent, {
        ...cookiePromptBottomSheetConfig,
      });

      close$ = this.bottomSheetRef.afterDismissed();
    } else {
      this.dialogRef = this._dialog.open(CookiePromptDrawerComponent, {
        ...partialGrayedScreenModalConfig,
        autoFocus: false,
        disableClose: true,
      });
      close$ = this.dialogRef.afterClosed();
    }

    close$.subscribe((action: CookieActions) => {
      this.logger.log('COOKIE', 'ACTION', { action });
      this.promptInProgress = false;
      this.actionHandler(action);
    });
  }

  cookieManage() {
    this.logger.log('COOKIE', 'MANAGE');
    let close$: Observable<CookieActions>;

    if (Utils.isMobile()) {
      this.bottomSheetRef = this._bottomSheet.open(ManageCookiesPageComponent, {
        ...cookiePromptBottomSheetConfig,
      });
      close$ = this.bottomSheetRef.afterDismissed();
    } else {
      this.dialogRef = this._dialog.open(ManageCookiesPageComponent, {
        ...partialGrayedScreenModalConfig,
        autoFocus: false,
        disableClose: true,
      });
      close$ = this.dialogRef.afterClosed();
    }

    close$.subscribe((action: CookieActions) => {
      this.logger.log('COOKIE', 'ACTION', { action });
      this.promptInProgress = false;
      this.actionHandler(action);
    });
  }

  private actionHandler(action?: CookieActions) {
    switch (action) {
      case CookieActions.ACCEPT: {
        sessionStorage.setItem(SNAME.ACCEPT_COOKIES, 'true');
        // FOR QUEBEC set to false
        sessionStorage.setItem(SNAME.ACCEPT_NONESSENTIAL, 'true');
        this.check();
        break;
      }
      case CookieActions.MANAGE: {
        this.cookieManage();
        break;
      }
      case CookieActions.NOTICE: {
        this.router.navigate([Utils.isCheckout() ? 'checkout' : 'user-portal', 'cookies-notice']);
        break;
      }
      case CookieActions.TERMS: {
        this.router.navigate([Utils.isCheckout() ? 'checkout' : 'user-portal', 'cookies-notice']);
        break;
      }
      case CookieActions.ACCEPT_ONLY_ESSENTIAL:
        sessionStorage.setItem(SNAME.ACCEPT_COOKIES, 'true');
        sessionStorage.setItem(SNAME.ACCEPT_NONESSENTIAL, 'false');
        this.check();
        break;

      default:
        this.check();
    }
  }

  close(action: CookieActions) {
    if (Utils.isMobile()) {
      this.bottomSheetRef.dismiss(action);
    } else {
      this.dialogRef.close(action);
    }
  }
}
