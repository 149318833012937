import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { PilotAllowlistingService } from '@api/index';
import { CookieAcceptService } from '@cookie/cookie-accept.service';
import { ENV_CONFIG } from '@core/interfaces/configs';
import { ENVCONFIG } from '@core/tokens/configs';
import { isCheckout } from '@core/utils';
import { catchError } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PbbFormFieldErrorComponent } from '../../../core/components/pbb-form-field-error/pbb-form-field-error.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'pbb-otc-submit',
  templateUrl: './otc-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    PbbFormFieldErrorComponent,
    MatButtonModule,
    NgIf,
    TranslateModule,
  ],
})
export class OtcSubmitComponent implements OnInit {
  otcCodeFormControl = new FormControl('', {
    validators: [Validators.minLength(6), Validators.maxLength(6)],
  });
  isCheckout = false;
  constructor(
    public dialogRef: MatDialogRef<OtcSubmitComponent>,
    private readonly cookieService: CookieAcceptService,
    private otcService: PilotAllowlistingService,
    private cdr: ChangeDetectorRef,
    @Inject(ENVCONFIG) private envConfig: ENV_CONFIG
  ) {}

  ngOnInit(): void {
    this.isCheckout = isCheckout();
  }

  submit() {
    if (this.otcCodeFormControl?.value?.toLowerCase() === 'skipme' && this.envConfig.env !== 'prod') {
      this.dialogRef.close();
      return;
    }
    this.otcService
      .verifyPilotOtc(this.otcCodeFormControl?.value || '')
      .pipe(
        catchError((err) => {
          this.otcCodeFormControl.setErrors({
            invalidOTC: true,
          });
          this.cdr.markForCheck();
          throw err;
        })
      )
      .subscribe((res) => {
        this.dialogRef.close();
      });
  }
}
