import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDeviceManagementService } from '@api/index';
import { AuthConfig } from '@auth/authConfig';
import { CookieAcceptService } from '@cookie/cookie-accept.service';
import { CNAME } from '@core/constants/cookies';
import { SNAME } from '@core/constants/storage';
import { FormStateService } from '@core/services/form-state.service';
import { LoggerService } from '@core/services/logger.service';
import { AUTHCONFIG } from '@core/tokens/configs';
import * as Utils from '@core/utils';
import { Store } from '@ngrx/store';
import * as CoreActions from '@store/core/core.actions';
import { UserActions } from '@store/user/user.actions';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import jwtDecode from 'jwt-decode';
import { Observable, catchError, map, of } from 'rxjs';
export interface AuthState {
  device_ref?: string;
  name?: string;
  sub?: string;
  webauthn_enabled?: boolean;
  trusted_device?: boolean;
  active_session?: boolean;
  cookie_acceptance?: boolean;
}

export interface TokenState {
  nonce: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accessToken!: string;
  private userProfileRef: string = '';
  constructor(
    @Inject(AUTHCONFIG) private authConfig: AuthConfig,
    private readonly store: Store,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private userDeviceManagementService: UserDeviceManagementService,
    private gtmService: GoogleTagManagerService,
    private formStateService: FormStateService,
    private window: Window,
    private cookieService: CookieAcceptService
  ) {}
  private signInSuccess(refresh: boolean = false) {
    refresh
      ? this.logger.log('AUTH', 'REFRESH TOKEN SUCCESS')
      : this.logger.log('AUTH', 'SIGNIN SUCCESS', this.userProfileRef);
    this.logger.log('AUTH', 'STATE', this.authState());
    this.prepareDeviceId();
  }
  private prepareDeviceId() {
    const sessionDeviceId = this.window.sessionStorage.getItem(Utils.getSessionDeviceIdKeyName(this.userProfileRef));
    if (sessionDeviceId && sessionDeviceId !== 'undefined') {
      this.store.dispatch(CoreActions.attachDeviceSuccess({ deviceId: sessionDeviceId, userRef: this.userProfileRef }));
      return;
    }

    const { sub, device_ref } = this.authState();
    if (sub && sub === this.userProfileRef && device_ref) {
      this.store.dispatch(CoreActions.attachDeviceSuccess({ deviceId: device_ref, userRef: this.userProfileRef }));
    } else {
      this.store.dispatch(CoreActions.attachDevice({ userRef: this.userProfileRef }));
    }
  }

  signout(redirectURL?: string, clearUserToken?: boolean, skipRedirect = false): Promise<boolean> | null {
    this.logger.log('AUTH', 'SIGNOUT SUCCESS');
    this.formStateService.hasChange = false;
    this.window.sessionStorage.removeItem(SNAME.OIDCCODE);
    this.window.sessionStorage.removeItem(SNAME.PERSONAL_DETAILS);
    this.window.sessionStorage.removeItem(Utils.getSessionDeviceIdKeyName(this.userProfileRef));
    this.window.sessionStorage.removeItem(SNAME.ADDRESS_CHANGED);
    this.accessToken = '';
    Utils.setCookie(CNAME.PBB_UI_TOKEN, null, -1);
    this.userProfileRef = '';

    this.store.dispatch(UserActions.signout());

    // cleanup user jws cookie on user profile deleting, unlink FI
    if (clearUserToken) {
      localStorage.removeItem(SNAME.USER_JWS);
      this.logger.log('AUTH', 'USER JWS CLEARED');
    }
    const authState = this.authState();
    this.logger.log('AUTH', 'STATE', authState);
    if (skipRedirect) {
      return null;
    }

    if (redirectURL) {
      return this.router.navigate([this.authConfig.basePath + redirectURL], {
        state: {
          skip: true,
        },
      });
    }
    return this.router.navigateByUrl(this.authConfig.basePath + '/auth');
  }

  refreshToken() {
    this.signInSuccess(true);
  }

  setUser(userProfileRef: string) {
    this.logger.log('AUTH', 'SIGNING IN', userProfileRef);
    const accessToken = Utils.getCookie(CNAME.PBB_UI_TOKEN) || '';
    if (!!accessToken) {
      try {
        const { nonce } = jwtDecode<TokenState>(accessToken);
        if (!nonce) {
          this.logger.error('AUTH', 'SIGNIN FAILED', 'INVALID TOKEN');
          return;
        }
        if (userProfileRef === nonce) {
          this.userProfileRef = nonce;
          this.accessToken = accessToken;
          this.signInSuccess();
          // Push Google Manager Tag (used requested format)
          if (this.cookieService.nonEssential) {
            this.gtmService.pushTag({
              event: 'Interac_participant_user_ID',
              user_ID: userProfileRef,
            });
          }
          this.userProfileRef = userProfileRef;
          this.store.dispatch(UserActions.setUserProfileRef({ userProfileRef }));
          return;
        }
        this.logger.error('AUTH', 'SIGNIN FAILED', 'INVALID TOKEN');
      } catch (e) {
        this.logger.error('AUTH', 'SIGNIN FAILED', e);
      }
    }
    this.logger.log('AUTH', 'SIGNIN FAILED', 'TOKEN NOT AVAILABLE');
  }

  isActiveSession(): boolean {
    return !!(this.accessToken && this.userProfileRef);
  }

  getDeviceStatus(): Observable<AuthState | null> {
    const userJWS = localStorage.getItem(SNAME.USER_JWS);
    if (!userJWS) {
      return of(null);
    }
    const { sub, device_ref } = jwtDecode<AuthState>(userJWS);
    return this.userDeviceManagementService.checkDeviceTrusted(`Bearer ${userJWS}`, sub || '').pipe(
      map(({ user_jws }) => {
        this.store.dispatch(CoreActions.attachDeviceSuccess({ deviceId: device_ref || '', userRef: sub || '' }));
        return this.setUserJWS(user_jws);
      }),
      catchError((err) => {
        this.setUserJWS();
        this.signout();
        throw err;
      })
    );
  }

  setUserJWS(userJWS?: string, skipCheckCookie = false): AuthState | null {
    if (!userJWS) {
      localStorage.removeItem(SNAME.USER_JWS);
      this.window.sessionStorage.removeItem(SNAME.ACCEPT_COOKIES);
      return null;
    }
    try {
      const { trusted_device } = jwtDecode<AuthState>(userJWS);
      if (trusted_device) {
        localStorage.setItem(SNAME.USER_JWS, userJWS);
      } else {
        localStorage.removeItem(SNAME.USER_JWS);
        if (!skipCheckCookie) {
          this.window.sessionStorage.removeItem(SNAME.ACCEPT_COOKIES);
        }
      }

      return this.authState();
    } catch (e) {
      localStorage.removeItem(SNAME.USER_JWS);
      this.logger.error('AUTH', 'INVALID USER JWS');
      return null;
    }
  }
  authState(): AuthState {
    return {
      ...(localStorage.getItem(SNAME.USER_JWS) ? jwtDecode<AuthState>(localStorage.getItem(SNAME.USER_JWS) || '') : {}),
      active_session: this.isActiveSession(),
    };
  }
}
