import { AccountType } from '@api/model/accountType';
import { ConsentGrantRequest } from '@api/model/consentGrantRequest';
import { Order } from '@api/model/order';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CheckoutFlowState, CheckoutState } from './checkout.interface';

const FLAG_SHIPPING_REQUIRED = 'SHIPPING_ADDRESS';

export const selectCheckoutFlow = createFeatureSelector<CheckoutFlowState>('checkoutFlow');
export const selectCheckout = createSelector(selectCheckoutFlow, (state: CheckoutFlowState) => state?.checkout);

export const selectConsentId = createSelector(selectCheckout, (state: CheckoutState) => state.consentId);
export const selectConsent = createSelector(selectCheckout, (state: CheckoutState) => state?.consent);
export const selectConsentLoaded = createSelector(selectCheckout, (state: CheckoutState) => {
  return state.consent?.data && !state.consent?.error && !state.consent?.isLoading;
});

export const selectIsShippingRequired = createSelector(
  selectCheckout,
  (state: CheckoutState) =>
    !!state.consent?.data?.merchant_required_contact_fields?.find((item) => item === FLAG_SHIPPING_REQUIRED)
);

export const selectPaymentMethodsNotSupportedBy = createSelector(
  selectCheckout,
  (state: CheckoutState) => state.paymentMethodsNotSupportedBy
);

export const selectSelectedShippingAddressRef = createSelector(
  selectCheckout,
  (state: CheckoutState) => state.consentGrant?.payload?.shipping_address?.shipping_address_ref || ''
);

export const selectConsentGrant = createSelector(selectCheckout, (state: CheckoutState) => state.consentGrant);

export const selectConsentGrantPayload = createSelector(
  selectCheckout,
  (state: CheckoutState) => state?.consentGrant?.payload as ConsentGrantRequest
);

export const selectIsDirectToSuccess = createSelector(selectCheckout, (state: CheckoutState): boolean | undefined =>
  state?.consent?.data ? state.consent.data.order.placement_mode === Order.PlacementModeEnum.Express : undefined
);

export const selectConsentGrantResponseStatus = createSelector(selectCheckout, (state: CheckoutState) => {
  return {
    status: state.consentGrant?.consentGrantResponse?.status,
  };
});

export const selectConsentGrantResponse = createSelector(selectCheckout, (state: CheckoutState) => {
  return state.consentGrant?.consentGrantResponse;
});

export const selectMerchantName = createSelector(
  selectCheckout,
  (state: CheckoutState): string => state.consent?.data?.store?.name || ''
);

export const selectMerchantSupportedPaymentMethods = createSelector(
  selectCheckout,
  (state: CheckoutState): AccountType[] | undefined => state.consent?.data?.merchant_supported_payment_methods
);

export const selectShippingCost = createSelector(selectCheckout, (state: CheckoutState) => {
  return state.shippingCost;
});
export const selectConsentGrantPaymentAmount = createSelector(
  selectCheckout,
  (state: CheckoutState) => state.consent?.data?.payment?.amount || 0
);
export const selectConsentGrantSecured = createSelector(
  selectCheckout,
  (state: CheckoutState) => state.consent?.data?.secured
);

export const selectIdvStatus = createSelector(
  selectCheckout,
  (state: CheckoutState) => state.idvStatus
);
