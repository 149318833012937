<div class="content_main-container">
  <pbb-lang-toggle></pbb-lang-toggle>
  <h2 class="mb-8 text-dark" id="cookie_manage_title">{{ 'COOKIE.PROMPT.MANAGE.TITLE' | translate }}</h2>
  <span class="mb-32 mat-headline-4 subtitle-3 text-grey-subtitle" id="cookie_manage_title">{{
    'COOKIE.PROMPT.DESCRIPTION' | translate
  }}</span>

  <div class="content_main-button-list-cookie-header">
    <span class="subtitle-3 text-dark">{{ 'COOKIE.PROMPT.MANAGE.ESSENTIAL' | translate }} </span>
    <span class="text-desc mat-body-1 text-gray-active float-right mr-8" id="cookie_manage_essential-required">
      {{ 'COOKIE.PROMPT.MANAGE.REQUIRED' | translate }}
    </span>
  </div>

  <span class="mat-headline-4 text-grey-subtitle subtitle-4 mt-16 mb-24" id="cookie_manage_essential-desc">
    {{ 'COOKIE.PROMPT.MANAGE.ESSENTIAL.DESCRIPTION' | translate }}
  </span>

  <mat-divider></mat-divider>

  <div class="content_main-button-list-cookie-header">
    <span class="subtitle-3 text-dark">
      {{ 'COOKIE.PROMPT.MANAGE.NON_ESSENTIAL' | translate }}
    </span>
    <span class="float-right">
      <mat-checkbox
        color="primary"
        id="cookie_manage_non_essential-toggle"
        [checked]="true"
        (change)="changeCookieSelected($event)"
      >
      </mat-checkbox>
    </span>
  </div>

  <span class="mat-headline-4 text-grey-subtitle subtitle-4 mt-16 mb-24" id="cookie_manage_non_essential-desc">
    {{ 'COOKIE.PROMPT.MANAGE.NON_ESSENTIAL.DESCRIPTION' | translate }}
  </span>

  <p class="mat-headline-4 text-grey-subtitle mb-8" id="cookie_prompt_desc_more">
    <span>
      {{ 'COOKIE.PROMPT.LEARN_MORE' | translate }}
      <a
        href="{{ 'COOKIE.PROMPT.COOKIE_LINK' | translate }}"
        target="_blank"
        rel="noopener"
        class="text-underlined cookie-terms"
        >{{ 'COOKIE.LINKS.TNC.LINK' | translate }}</a
      >
    </span>
  </p>
  <div class="content_main-container-button-list">
    <div
      class="mb-8"
      [ngClass]="
        isMobile
          ? 'content_main-button-list-cookie-actions-mobile'
          : 'content_main-button-list-cookie-actions flex-row flex-end'
      "
    >
      <button mat-flat-button color="primary" id="cookie_manage_accept" (click)="save()">
        {{ 'COOKIE.PROMPT.ACCEPT' | translate }}
      </button>
    </div>
  </div>
</div>
