import { MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { MatDialogConfig } from '@angular/material/dialog';

export const fullScreenModalConfig: Partial<MatDialogConfig> = {
  panelClass: 'content_overlay-full',
  backdropClass: 'content_overlay-full-backdrop',
  width: '100vw',
  maxWidth: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  disableClose: true,
};

export const partialScreenModalConfig: Partial<MatDialogConfig> = {
  panelClass: 'content_overlay-partial',
  backdropClass: 'content_overlay-partial-backdrop',
  // width: '100vw',
  // maxWidth: '100vw',
  // height: '100vh',
  // maxHeight: '100vh',
  disableClose: true,
};

export const partialGrayedScreenModalConfig: Partial<MatDialogConfig> = {
  panelClass: 'content_overlay-partial',
  backdropClass: 'content_overlay-partial-graybackdrop',
  // width: '100vw',
  // maxWidth: '100vw',
  // height: '100vh',
  // maxHeight: '100vh',
  disableClose: false,
};

export const fullScreenModalGreyConfig: Partial<MatDialogConfig> = {
  ...fullScreenModalConfig,
  panelClass: ['content_overlay-full', 'bg-grey'],
  backdropClass: ['content_overlay-full-backdrop', 'bg-grey'],
};

export const cookiePromptBottomSheetConfig: Partial<MatBottomSheetConfig> = {
  panelClass: 'content_main-drawer',
  backdropClass: 'content_overlay-partial-graybackdrop',
  disableClose: true,
  hasBackdrop: true,
};
