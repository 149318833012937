import { Directive, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class UntilDestroy implements OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  subscriptions: Subscription = new Subscription();
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.subscriptions.unsubscribe();
  }
}
